export enum SupportedLanguages {
  English = "en",
  Spanish = "es",
  German = "de",
  Chinese = "zh",
  French = "fr",
  Italian = "it",
  Portuguese = "pt",
  Japanese = "ja",
  Russian = "ru",
  Hindi = "hi",
  Korean = "ko",
}

export enum SupportedCountries {
  UnitedStates = "US",
  Canada = "CA",
  Mexico = "MX",
  Argentina = "AR",
  Colombia = "CO",
  Ecuador = "EC",
  Brazil = "BR",
  Peru = "PE",
  CostaRica = "CR",
  ElSalvador = "SV",
  Guatemala = "GT",
  DominicanRepublic = "DO",
  Nicaragua = "NI",
}

export const LanguageToCountry: Record<SupportedLanguages, string> = {
  [SupportedLanguages.English]: "US",
  [SupportedLanguages.Spanish]: "ES",
  [SupportedLanguages.German]: "DE",
  [SupportedLanguages.Chinese]: "CN",
  [SupportedLanguages.French]: "FR",
  [SupportedLanguages.Italian]: "IT",
  [SupportedLanguages.Portuguese]: "PT",
  [SupportedLanguages.Japanese]: "JP",
  [SupportedLanguages.Russian]: "RU",
  [SupportedLanguages.Hindi]: "IN",
  [SupportedLanguages.Korean]: "KR",
};
