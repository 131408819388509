import { VoiceAccent, VoiceAge, VoiceGender } from "@/enums/agencies";
import { SupportedLanguages } from "@/enums/internationalization";
import { VoiceOption } from "@/types/agencies";

export const DEFAULT_VOICE_ID = "OYTbf65OHHFELVut7v2H";

export const VOICE_OPTIONS: VoiceOption[] = [
  {
    name: "Joseph",
    id: "J5iaaqzR5zn6HFG4jV3b",
    supportedLanguages: [
      SupportedLanguages.English,
      SupportedLanguages.Spanish,
    ],
    labels: {
      accent: VoiceAccent.AMERICAN,
      age: VoiceAge.YOUNG,
      gender: VoiceGender.MALE,
    },
  },
  {
    name: "Jerry",
    id: "BRXqZ6YAQpe2chRY9PCd",
    supportedLanguages: [SupportedLanguages.English],
    labels: {
      accent: VoiceAccent.AMERICAN,
      age: VoiceAge.MIDDLE_AGED,
      gender: VoiceGender.MALE,
    },
  },
  {
    name: "Adam",
    id: "pNInz6obpgDQGcFmaJgB",
    supportedLanguages: [
      SupportedLanguages.English,
      SupportedLanguages.Spanish,
    ],
    labels: {
      isOld: true,
      accent: VoiceAccent.AMERICAN,
      age: VoiceAge.MIDDLE_AGED,
      gender: VoiceGender.MALE,
    },
  },
  {
    name: "Brian",
    id: "nPczCjzI2devNBz1zQrb",
    supportedLanguages: [
      SupportedLanguages.English,
      SupportedLanguages.Spanish,
    ],
    labels: {
      accent: VoiceAccent.AMERICAN,
      age: VoiceAge.MIDDLE_AGED,
      gender: VoiceGender.MALE,
    },
  },
  {
    name: "Armando",
    id: "TWUKKXAylkYxxlPe4gx0",
    supportedLanguages: [
      SupportedLanguages.English,
      SupportedLanguages.Spanish,
    ],
    labels: {
      accent: VoiceAccent.AMERICAN,
      age: VoiceAge.YOUNG,
      gender: VoiceGender.MALE,
    },
  },
  {
    name: "Dakota",
    id: "P7x743VjyZEOihNNygQ9",
    supportedLanguages: [
      SupportedLanguages.English,
      SupportedLanguages.Spanish,
    ],
    labels: {
      accent: VoiceAccent.AFRICAN_AMERICAN,
      age: VoiceAge.MIDDLE_AGED,
      gender: VoiceGender.FEMALE,
    },
  },
  {
    name: "Tiffany",
    id: "x9leqCOAXOcmC5jtkq65",
    supportedLanguages: [SupportedLanguages.English],
    labels: {
      isOld: true,
      accent: VoiceAccent.AMERICAN,
      age: VoiceAge.MIDDLE_AGED,
      gender: VoiceGender.FEMALE,
    },
  },
  {
    name: "Andrea",
    id: "wVZ5qbJFYF3snuC65nb4",
    supportedLanguages: [
      SupportedLanguages.English,
      SupportedLanguages.Spanish,
    ],
    labels: {
      isOld: true,
      accent: VoiceAccent.AMERICAN,
      age: VoiceAge.MIDDLE_AGED,
      gender: VoiceGender.FEMALE,
    },
  },
  {
    name: "Grace",
    id: "oWAxZDx7w5VEj9dCyTzz",
    supportedLanguages: [SupportedLanguages.English],
    labels: {
      accent: VoiceAccent.AMERICAN_SOUTHERN,
      age: VoiceAge.YOUNG,
      gender: VoiceGender.FEMALE,
    },
  },
  {
    name: "Charlotte",
    id: "PHpDeHqb4o2WMLI56GjJ",
    supportedLanguages: [SupportedLanguages.English],
    labels: {
      accent: VoiceAccent.AMERICAN,
      age: VoiceAge.YOUNG,
      gender: VoiceGender.FEMALE,
    },
  },
  {
    name: "Annie",
    id: "c4TutCiAuWP4vwb1xebb",
    supportedLanguages: [SupportedLanguages.English],
    labels: {
      accent: VoiceAccent.AMERICAN_SOUTHERN,
      age: VoiceAge.YOUNG,
      gender: VoiceGender.FEMALE,
    },
  },
  {
    name: "John South",
    id: "45S0pWZx6D2J6Erf9LJj",
    supportedLanguages: [SupportedLanguages.English],
    labels: {
      accent: VoiceAccent.AMERICAN_SOUTHERN,
      age: VoiceAge.MIDDLE_AGED,
      gender: VoiceGender.MALE,
    },
  },
  {
    name: "Kasi",
    id: "uTMDBuhuwkx5KQDLl1NO",
    supportedLanguages: [
      SupportedLanguages.English,
      SupportedLanguages.Spanish,
    ],
    dateAdded: "2024-07-31",
    labels: {
      accent: VoiceAccent.AMERICAN,
      age: VoiceAge.YOUNG,
      gender: VoiceGender.FEMALE,
    },
  },
  {
    name: "Cody",
    id: "fWOMk8z2ICscOW6N7dQq",
    supportedLanguages: [
      SupportedLanguages.English,
      SupportedLanguages.Spanish,
    ],
    dateAdded: "2024-07-31",
    labels: {
      accent: VoiceAccent.AMERICAN,
      age: VoiceAge.YOUNG,
      gender: VoiceGender.MALE,
    },
  },
  {
    name: "Hope",
    id: "OYTbf65OHHFELVut7v2H",
    supportedLanguages: [
      SupportedLanguages.English,
      SupportedLanguages.Spanish,
    ],
    dateAdded: "2024-07-31",
    labels: {
      accent: VoiceAccent.AMERICAN,
      age: VoiceAge.YOUNG,
      gender: VoiceGender.FEMALE,
    },
  },
  {
    name: "Ellie",
    id: "8VoUGTZdmEnCQziZBMzs",
    supportedLanguages: [
      SupportedLanguages.English,
      SupportedLanguages.Spanish,
    ],
    dateAdded: "2024-07-31",
    labels: {
      accent: VoiceAccent.AMERICAN,
      age: VoiceAge.YOUNG,
      gender: VoiceGender.FEMALE,
    },
  },
  {
    name: "Tanya",
    id: "Bwff1jnzl1s94AEcntUq",
    supportedLanguages: [
      SupportedLanguages.English,
      SupportedLanguages.Spanish,
    ],
    dateAdded: "2024-07-31",
    labels: {
      accent: VoiceAccent.AMERICAN,
      age: VoiceAge.MIDDLE_AGED,
      gender: VoiceGender.FEMALE,
    },
  },
  {
    name: "Enrique",
    id: "gbTn1bmCvNgk0QEAVyfM",
    supportedLanguages: [
      SupportedLanguages.English,
      SupportedLanguages.Spanish,
    ],
    dateAdded: "2024-08-15",
    labels: {
      accent: VoiceAccent.MEXICAN,
      age: VoiceAge.MIDDLE_AGED,
      gender: VoiceGender.MALE,
    },
  },
  {
    name: "Valeria",
    id: "9oPKasc15pfAbMr7N6Gs",
    supportedLanguages: [
      SupportedLanguages.English,
      SupportedLanguages.Spanish,
    ],
    dateAdded: "2024-08-15",
    labels: {
      accent: VoiceAccent.MEXICAN,
      age: VoiceAge.YOUNG,
      gender: VoiceGender.FEMALE,
    },
  },
  {
    name: "Arnaldo",
    id: "FplHoz0VvJ9jC7lf3D7V",
    supportedLanguages: [
      SupportedLanguages.English,
      SupportedLanguages.Spanish,
    ],
    dateAdded: "2024-08-15",
    labels: {
      accent: VoiceAccent.MEXICAN,
      age: VoiceAge.YOUNG,
      gender: VoiceGender.MALE,
    },
  },
  {
    name: "Emiliano",
    id: "G0T6aZTefpQgD4rQBc9s",
    supportedLanguages: [SupportedLanguages.Spanish],
    dateAdded: "2024-08-15",
    labels: {
      accent: VoiceAccent.MEXICAN,
      age: VoiceAge.MIDDLE_AGED,
      gender: VoiceGender.MALE,
    },
  },
  {
    name: "Tony",
    id: "7bpaC4wMKttCpc5x8hq1",
    supportedLanguages: [
      SupportedLanguages.English,
      SupportedLanguages.Spanish,
    ],
    dateAdded: "2024-08-15",
    labels: {
      accent: VoiceAccent.MEXICAN,
      age: VoiceAge.YOUNG,
      gender: VoiceGender.MALE,
    },
  },
  {
    name: "Lisa",
    id: "LSah3F2oqv0qunZV6QDs",
    supportedLanguages: [SupportedLanguages.English],
    dateAdded: "2024-08-15",
    labels: {
      accent: VoiceAccent.AMERICAN_SOUTHERN,
      age: VoiceAge.YOUNG,
      gender: VoiceGender.FEMALE,
    },
  },
  {
    name: "Maribeth",
    id: "JEAgwU0JZFGxl2KjC3if",
    supportedLanguages: [SupportedLanguages.English],
    dateAdded: "2024-08-15",
    labels: {
      accent: VoiceAccent.AMERICAN_SOUTHERN,
      age: VoiceAge.YOUNG,
      gender: VoiceGender.FEMALE,
    },
  },
  {
    name: "Gabriela",
    id: "gxSxrhNNXvdHpOH0EHjV",
    supportedLanguages: [
      SupportedLanguages.English,
      SupportedLanguages.Spanish,
    ],
    dateAdded: "2024-09-18",
    labels: {
      accent: VoiceAccent.MEXICAN,
      age: VoiceAge.YOUNG,
      gender: VoiceGender.FEMALE,
    },
  },
  {
    name: "Veronica",
    id: "IOyj8WtBHdke2FjQgGAr",
    supportedLanguages: [
      SupportedLanguages.English,
      SupportedLanguages.Spanish,
    ],
    dateAdded: "2024-09-18",
    labels: {
      accent: VoiceAccent.COLOMBIAN,
      age: VoiceAge.YOUNG,
      gender: VoiceGender.FEMALE,
    },
  },
  {
    name: "Antonio",
    id: "4GMf9CnVFI2n0w4K1Gm4",
    supportedLanguages: [
      SupportedLanguages.English,
      SupportedLanguages.Spanish,
    ],
    dateAdded: "2024-09-18",
    labels: {
      accent: VoiceAccent.COLOMBIAN,
      age: VoiceAge.MIDDLE_AGED,
      gender: VoiceGender.MALE,
    },
  },
  {
    name: "Javier",
    id: "IoWn77TsmQnza94sYlfg",
    supportedLanguages: [
      SupportedLanguages.English,
      SupportedLanguages.Spanish,
    ],
    dateAdded: "2024-09-18",
    labels: {
      accent: VoiceAccent.DOMINICAN,
      age: VoiceAge.YOUNG,
      gender: VoiceGender.MALE,
    },
  },
  {
    name: "Allison",
    id: "xctasy8XvGp2cVO9HL9k",
    supportedLanguages: [SupportedLanguages.English],
    dateAdded: "2024-09-18",
    labels: {
      accent: VoiceAccent.AMERICAN,
      age: VoiceAge.YOUNG,
      gender: VoiceGender.FEMALE,
    },
  },
  {
    name: "Seth",
    id: "c6kFzbpMaJ8UMD5P6l72",
    supportedLanguages: [
      SupportedLanguages.English,
      SupportedLanguages.Spanish,
    ],
    dateAdded: "2024-09-18",
    labels: {
      accent: VoiceAccent.AMERICAN,
      age: VoiceAge.MIDDLE_AGED,
      gender: VoiceGender.MALE,
    },
  },
  {
    name: "Mariale",
    id: "qHkrJuifPpn95wK3rm2A",
    supportedLanguages: [
      SupportedLanguages.English,
      SupportedLanguages.Spanish,
    ],
    dateAdded: "2024-09-18",
    labels: {
      accent: VoiceAccent.COLOMBIAN,
      age: VoiceAge.YOUNG,
      gender: VoiceGender.FEMALE,
    },
  },

  {
    name: "Lea",
    id: "7eVMgwCnXydb3CikjV7a",
    supportedLanguages: [SupportedLanguages.English, SupportedLanguages.German],
    dateAdded: "2024-11-04",
    labels: {
      age: VoiceAge.MIDDLE_AGED,
      gender: VoiceGender.FEMALE,
    },
  },
  {
    name: "Arturo",
    id: "dnVg3y7lzI5AhOQwr9rk",
    supportedLanguages: [SupportedLanguages.Spanish],
    dateAdded: "2024-11-04",
    labels: {
      accent: VoiceAccent.ECUADORIAN,
      age: VoiceAge.YOUNG,
      gender: VoiceGender.MALE,
    },
  },
  {
    name: "Melissa",
    id: "ktIHLnLSz4CrKuMPxoki",
    supportedLanguages: [
      SupportedLanguages.English,
      SupportedLanguages.Spanish,
    ],
    dateAdded: "2024-11-04",
    labels: {
      accent: VoiceAccent.ARGENTINIAN,
      age: VoiceAge.YOUNG,
      gender: VoiceGender.FEMALE,
    },
  },
  {
    name: "Alvaro",
    id: "DtKLnJH3oqZDoKUbPjY0",
    supportedLanguages: [
      SupportedLanguages.English,
      SupportedLanguages.Spanish,
    ],
    dateAdded: "2025-01-07",
    labels: {
      accent: VoiceAccent.ARGENTINIAN,
      age: VoiceAge.YOUNG,
      gender: VoiceGender.MALE,
    },
  },
  {
    name: "Javier",
    id: "FrrTxu4nrplZwLlMy2kD",
    supportedLanguages: [
      SupportedLanguages.English,
      SupportedLanguages.Spanish,
    ],
    dateAdded: "2025-01-07",
    labels: {
      accent: VoiceAccent.ARGENTINIAN,
      age: VoiceAge.MIDDLE_AGED,
      gender: VoiceGender.MALE,
    },
  },
  {
    name: "Camila",
    id: "FHfd6ZxF7kDHkEEQH5Xm",
    supportedLanguages: [
      SupportedLanguages.English,
      SupportedLanguages.Spanish,
    ],
    dateAdded: "2025-01-07",
    labels: {
      accent: VoiceAccent.ARGENTINIAN,
      age: VoiceAge.YOUNG,
      gender: VoiceGender.FEMALE,
    },
  },
  {
    name: "Paula",
    id: "s9cL36VV5JICSuKEsD3L",
    supportedLanguages: [
      SupportedLanguages.English,
      SupportedLanguages.Spanish,
    ],
    dateAdded: "2025-01-07",
    labels: {
      accent: VoiceAccent.ARGENTINIAN,
      age: VoiceAge.MIDDLE_AGED,
      gender: VoiceGender.FEMALE,
    },
  },
  {
    name: "Alejandro",
    id: "YKUjKbMlejgvkOZlnnvt",
    supportedLanguages: [SupportedLanguages.Spanish],
    dateAdded: "2025-01-16",
    labels: {
      accent: VoiceAccent.MEXICAN,
      age: VoiceAge.YOUNG,
      gender: VoiceGender.MALE,
    },
  },
  {
    name: "Stacey",
    id: "hkfHEbBvdQFNX4uWHqRF",
    supportedLanguages: [SupportedLanguages.Chinese],
    dateAdded: "2025-03-28",
    labels: {
      age: VoiceAge.YOUNG,
      gender: VoiceGender.FEMALE,
    },
  },
  {
    name: "James",
    id: "4VZIsMPtgggwNg7OXbPY",
    supportedLanguages: [SupportedLanguages.Chinese],
    dateAdded: "2025-03-28",
    labels: {
      age: VoiceAge.YOUNG,
      gender: VoiceGender.MALE,
    },
  },
  {
    name: "Martin",
    id: "WuLq5z7nEcrhppO0ZQJw",
    supportedLanguages: [SupportedLanguages.Chinese],
    dateAdded: "2025-03-28",
    labels: {
      age: VoiceAge.MIDDLE_AGED,
      gender: VoiceGender.MALE,
    },
  },
  {
    name: "Ana",
    id: "cyD08lEy76q03ER1jZ7y",
    supportedLanguages: [SupportedLanguages.Portuguese],
    dateAdded: "2025-03-28",
    labels: {
      age: VoiceAge.YOUNG,
      gender: VoiceGender.FEMALE,
    },
  },
  {
    name: "Manoel",
    id: "NKKvpecEshlHm99K9zn9",
    supportedLanguages: [SupportedLanguages.Portuguese],
    dateAdded: "2025-03-28",
    labels: {
      age: VoiceAge.YOUNG,
      gender: VoiceGender.MALE,
    },
  },
  {
    name: "Conrado",
    id: "jLIW7D3ar1H9coBmNcVS",
    supportedLanguages: [SupportedLanguages.Portuguese],
    dateAdded: "2025-03-28",
    labels: {
      age: VoiceAge.MIDDLE_AGED,
      gender: VoiceGender.MALE,
    },
  },
  {
    name: "Andrea",
    id: "6KQ52XTjdNd1FfUgGmV6",
    supportedLanguages: [SupportedLanguages.Italian],
    dateAdded: "2025-03-28",
    labels: {
      age: VoiceAge.MIDDLE_AGED,
      gender: VoiceGender.FEMALE,
    },
  },
  {
    name: "Raffaele",
    id: "HhBRXe1sPBLe9D9lX1HC",
    supportedLanguages: [SupportedLanguages.Italian],
    dateAdded: "2025-03-28",
    labels: {
      age: VoiceAge.MIDDLE_AGED,
      gender: VoiceGender.MALE,
    },
  },
  {
    name: "Linda",
    id: "3DPhHWXDY263XJ1d2EPN",
    supportedLanguages: [SupportedLanguages.Italian],
    dateAdded: "2025-03-28",
    labels: {
      age: VoiceAge.YOUNG,
      gender: VoiceGender.FEMALE,
    },
  },
  {
    name: "Susan",
    id: "v3V1d2rk6528UrLKRuy8",
    supportedLanguages: [SupportedLanguages.German],
    dateAdded: "2025-03-28",
    labels: {
      age: VoiceAge.MIDDLE_AGED,
      gender: VoiceGender.FEMALE,
    },
  },
  {
    name: "Christian",
    id: "Zk6PNhET4ocTIpiuaraU",
    supportedLanguages: [SupportedLanguages.German],
    dateAdded: "2025-03-28",
    labels: {
      age: VoiceAge.MIDDLE_AGED,
      gender: VoiceGender.MALE,
    },
  },
  {
    name: "Leo",
    id: "IvLWq57RKibBrqZGpQrC",
    supportedLanguages: [SupportedLanguages.Hindi],
    dateAdded: "2025-03-28",
    labels: {
      age: VoiceAge.MIDDLE_AGED,
      gender: VoiceGender.MALE,
    },
  },
  {
    name: "Devi",
    id: "MF4J4IDTRo0AxOO4dpFR",
    supportedLanguages: [SupportedLanguages.Hindi],
    dateAdded: "2025-03-28",
    labels: {
      age: VoiceAge.YOUNG,
      gender: VoiceGender.FEMALE,
    },
  },
  {
    name: "Marcel",
    id: "kENkNtk0xyzG09WW40xE",
    supportedLanguages: [SupportedLanguages.French],
    dateAdded: "2025-03-28",
    labels: {
      age: VoiceAge.MIDDLE_AGED,
      gender: VoiceGender.MALE,
    },
  },
  {
    name: "Lucien",
    id: "TQaDhGYcKI0vrQueAmVO",
    supportedLanguages: [SupportedLanguages.French],
    dateAdded: "2025-03-28",
    labels: {
      age: VoiceAge.YOUNG,
      gender: VoiceGender.MALE,
    },
  },
  {
    name: "Mariette",
    id: "aMSt68OGf4xUZAnLpTU8",
    supportedLanguages: [SupportedLanguages.French],
    dateAdded: "2025-03-28",
    labels: {
      age: VoiceAge.MIDDLE_AGED,
      gender: VoiceGender.FEMALE,
    },
  },
  {
    name: "Larisa",
    id: "AB9XsbSA4eLG12t2myjN",
    supportedLanguages: [SupportedLanguages.Russian],
    dateAdded: "2025-03-28",
    labels: {
      age: VoiceAge.YOUNG,
      gender: VoiceGender.FEMALE,
    },
  },
  {
    name: "Dimitry",
    id: "kwajW3Xh5svCeKU5ky2S",
    supportedLanguages: [SupportedLanguages.Russian],
    dateAdded: "2025-03-28",
    labels: {
      age: VoiceAge.YOUNG,
      gender: VoiceGender.MALE,
    },
  },
  {
    name: "Morioki",
    id: "8EkOjt4xTPGMclNlh1pk",
    supportedLanguages: [SupportedLanguages.Japanese],
    dateAdded: "2025-03-28",
    labels: {
      age: VoiceAge.YOUNG,
      gender: VoiceGender.FEMALE,
    },
  },
  {
    name: "Junichi",
    id: "wAWUBOIVEUw9IEUYoNzR",
    supportedLanguages: [SupportedLanguages.Japanese],
    dateAdded: "2025-03-28",
    labels: {
      age: VoiceAge.MIDDLE_AGED,
      gender: VoiceGender.MALE,
    },
  },
  {
    name: "Wonmoon",
    id: "H8ObVvroE5JXeeUSJakg",
    supportedLanguages: [SupportedLanguages.Korean],
    dateAdded: "2025-03-28",
    labels: {
      age: VoiceAge.MIDDLE_AGED,
      gender: VoiceGender.MALE,
    },
  },
  {
    name: "Jisoo",
    id: "AW5wrnG1jVizOYY7R1Oo",
    supportedLanguages: [SupportedLanguages.Korean],
    dateAdded: "2025-03-28",
    labels: {
      age: VoiceAge.YOUNG,
      gender: VoiceGender.FEMALE,
    },
  },
  {
    name: "Cassidy",
    id: "56AoDkrOh6qfVPDXZ7Pt",
    supportedLanguages: [SupportedLanguages.English],
    dateAdded: "2025-03-28",
    labels: {
      age: VoiceAge.MIDDLE_AGED,
      gender: VoiceGender.FEMALE,
    },
  },
  {
    name: "Adam Stone",
    id: "NFG5qt843uXKj4pFvR7C",
    supportedLanguages: [SupportedLanguages.English],
    dateAdded: "2025-03-28",
    labels: {
      accent: VoiceAccent.BRITISH,
      age: VoiceAge.MIDDLE_AGED,
      gender: VoiceGender.MALE,
    },
  },
  {
    name: "Clara",
    id: "k9KXsQFJqzAoomTCOrJB",
    supportedLanguages: [SupportedLanguages.English],
    dateAdded: "2025-03-28",
    labels: {
      accent: VoiceAccent.AMERICAN,
      age: VoiceAge.YOUNG,
      gender: VoiceGender.FEMALE,
    },
  },
  {
    name: "Mark",
    id: "UgBBYS2sOqTuMpoF3BR0",
    supportedLanguages: [SupportedLanguages.English],
    dateAdded: "2025-03-28",
    labels: {
      accent: VoiceAccent.AMERICAN,
      age: VoiceAge.YOUNG,
      gender: VoiceGender.MALE,
    },
  },
]
  .sort((a, b) => {
    if (a.dateAdded || b.dateAdded) {
      const aDate = new Date(a.dateAdded || "2020-01-01");
      const bDate = new Date(b.dateAdded || "2020-01-01");
      const monthAgo = new Date();
      monthAgo.setMonth(monthAgo.getMonth() - 1);

      if (aDate > monthAgo && bDate > monthAgo) {
        return a.name.localeCompare(b.name);
      }

      if (aDate > monthAgo) {
        return -1;
      }

      if (bDate > monthAgo) {
        return 1;
      }
    }

    return a.name.localeCompare(b.name);
  })
  .map((item) => {
    let isNew = false;
    if (item.dateAdded) {
      const dateAdded = new Date(item.dateAdded);
      const monthAgo = new Date();
      monthAgo.setMonth(monthAgo.getMonth() - 1);

      isNew = dateAdded > monthAgo;
    }

    return {
      ...item,
      labels: {
        ...item.labels,
        isNew: isNew,
      },
    } satisfies VoiceOption;
  });
