import { Org, OrgRequest } from "@/types/org";
import { api } from "@/utils/common/api";
import { GAIL_API } from "@/utils/common/constants/routes";

export const UpdateOrg = async (
  accessToken: string,
  org: OrgRequest
): Promise<Org | null> => {
  return await api<OrgRequest, Org>(
    accessToken,
    `${GAIL_API}/.internal/customer/organization`,
    "PATCH",
    org
  );
};
