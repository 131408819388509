export enum BRANDS {
  DESOL = "desol",
  GAIL = "default",
  DEL_TORO = "del_toro",
  MARIA = "maria",
  BANCO_AZTECA = "banco_azteca",
  ARNALDO = "arnaldo",
  WEESSIES = "weessies",
  ROCKET = "rocket",
  SEBAS = "sebas",
  TELEFONICA = "telefonica",
  MOVISTAR = "movistar",
}
