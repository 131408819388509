import { UserRoles } from "@/enums/user";
import { AgencyConnection, OrgUserRestrictions } from "@/types/agencies";
import { MainAlertProps } from "@/types/common/alerts";
import { apiLive } from "../common/api";

export const FetchAgencyIntegrations = async (
  getToken: () => Promise<string | undefined>,
  setIsLoading: (isSaving: boolean, text: string) => void,
  textForLoading: string
): Promise<AgencyConnection[] | null> => {
  try {
    setIsLoading(true, textForLoading);

    const data = await apiLive<AgencyConnection[]>(
      getToken,
      `/api/settings/agency/connection`
    );

    setIsLoading(false, "");

    return data;
  } catch (error) {
    setIsLoading(false, "");
    return null;
  }
};

export const HandleOpenBillingSession = async (
  getToken: () => Promise<string | undefined>,
  setAlert: (settings: MainAlertProps) => void
) => {
  const session = await apiLive(getToken, "/api/billing/session");

  if (!session) {
    setAlert({
      status: "error",
      message: "Failed to open billing, please contact support",
      secondsToClose: 10,
    });
    return;
  }

  window.open(session.url, "_blank");
};

export const GetDefaultOrgRestrictions = (): OrgUserRestrictions => {
  return {
    maxPerRole: { [UserRoles.Integration]: 0, [UserRoles.ReadOnly]: 0 },
    maxTotal: 5,
  };
};
