"use client";

import posthog from "posthog-js";
import { createContext, useContext, useEffect, useState } from "react";
import React from "react";
import { useTranslation } from "react-i18next";
import { useAuthContext } from "@/contexts/common/AuthContextProvider";
import { BRANDS } from "@/enums/brands";
import {
  SupportedCountries,
  SupportedLanguages,
} from "@/enums/internationalization";
import { SubscriptionStatus } from "@/enums/stripe";
import { Address, Org, OrgRequest, OrgServices, Owner } from "@/types/org";
import { AgentProfile, AgentProfileRequest } from "@/types/profile";
import { VOICE_OPTIONS } from "@/utils/agencies/voices";
import { api, apiLive } from "@/utils/common/api";
import { useAlert } from "./useAlert";

type UiTypes = {
  language: SupportedLanguages;
  country: SupportedCountries;
  brand: BRANDS;
  isUsingGalactus: boolean;
  allowedCountries: SupportedCountries[];
};

type ProfileProps = {
  ui: UiTypes;
  agentProfile: AgentProfile;
  saveAgentProfile: (profile: AgentProfileRequest) => Promise<void>;
  org: Org;
  saveOrg: (o: OrgRequest) => Promise<void>;
  refreshOrg: () => Promise<void>;
};

const getDefaultProfile = (): AgentProfile => {
  return {
    frequentlyAskedQuestions: [],
    operationSchedule: {
      timeZone: "America/New_York",
      businessSchedule: [],
      outboundSchedule: [],
    },
    languages: [SupportedLanguages.English],
    voice: VOICE_OPTIONS[0].id,
  };
};

const getDefaultAddress = (): Address => {
  return {
    addressLine1: "",
    addressLine2: "",
    city: "",
    state: "",
    zipCode: "",
    country: "",
  };
};

const getDefaultOwner = (): Owner => {
  return {
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
  };
};

const getDefaultServices = (): OrgServices => {
  return {
    auth0OrganizationId: "",
    salesforceId: "",
    stripeCustomerId: "",
    twilioAccountSid: "",
    twilioPhoneNumber: "",
    twilioVerifiedPhoneNumber: "",
    calendarTeamId: "",
    calendarDefaultEventId: "",
  };
};

const getDefaultOrg = (): Org => {
  return {
    id: "",
    address: getDefaultAddress(),
    createdAt: new Date().toISOString(),
    lastModifiedAt: new Date().toISOString(),
    details: {},
    isDemo: false,
    features: {
      campaignVersion: "",
    },
    owner: getDefaultOwner(),
    services: getDefaultServices(),
    localization: "en-US",
    name: "",
    subscriptionStatus: SubscriptionStatus.none,
  };
};

export const ProfileContext = createContext<ProfileProps>({
  ui: {
    language: SupportedLanguages.English,
    country: SupportedCountries.UnitedStates,
    brand: BRANDS.GAIL,
    isUsingGalactus: false,
    allowedCountries: [SupportedCountries.UnitedStates],
  },
  agentProfile: getDefaultProfile(),
  saveAgentProfile: async () => {},
  org: getDefaultOrg(),
  saveOrg: async () => {},
  refreshOrg: async () => {},
});

export const useProfile = () => useContext(ProfileContext)!;

const useProfileFunction = ({
  setAppLanguage,
}: {
  setAppLanguage: (language: string) => void;
}): ProfileProps => {
  const { getToken, user } = useAuthContext();
  const { setIsSaving, setAlert } = useAlert();
  const { t: ct } = useTranslation("common");

  const [profile, setProfile] = useState<AgentProfile>(getDefaultProfile());
  const [org, setOrg] = useState<Org>(getDefaultOrg());
  const [uiData, setUiData] = useState<UiTypes>({
    language: SupportedLanguages.English,
    country: SupportedCountries.UnitedStates,
    brand: BRANDS.GAIL,
    isUsingGalactus: false,
    allowedCountries: [SupportedCountries.UnitedStates],
  });
  const [allowedCountries, setAllowedCountries] = useState<
    SupportedCountries[]
  >([SupportedCountries.UnitedStates]);

  const updateFromOrg = (newOrg: Org, fromApi: boolean) => {
    const isUsingGalactus = newOrg.features?.campaignVersion.includes("2");
    const newBrand = (newOrg.details?.brand ?? BRANDS.GAIL) as BRANDS;
    const newLanguage =
      newOrg.localization && newOrg.localization.split("-").length > 1
        ? (newOrg.localization.split("-")[0] as SupportedLanguages)
        : SupportedLanguages.English;
    const newCountry =
      newOrg.localization && newOrg.localization.split("-").length > 1
        ? (newOrg.localization.split("-")[1] as SupportedCountries)
        : SupportedCountries.UnitedStates;

    if (
      fromApi &&
      newBrand != window.localStorage.getItem("brand") &&
      process.env.NEXT_PUBLIC_ENVIRONMENT !== "development"
    ) {
      window.localStorage.setItem("brand", newBrand);
      window.location.reload();
    }

    setUiData({
      language: newLanguage,
      country: newCountry,
      brand: newBrand,
      isUsingGalactus,
      allowedCountries: [SupportedCountries.UnitedStates],
    });

    setOrg(newOrg);

    if (fromApi) {
      setAppLanguage(newLanguage);
    }
  };

  const fetchProfile = async () => {
    const profile = await apiLive<AgentProfile>(getToken, "/api/profile");

    if (profile) {
      setProfile(profile);
      window.localStorage.setItem("agentProfile", JSON.stringify(profile));
    } else {
      setAlert({
        status: "error",
        message: ct("failedLoadingOrgSettings"),
        secondsToClose: 10,
      });
    }
  };

  const fetchOrg = async () => {
    const newOrg = await apiLive<Org>(getToken, "/api/org");

    if (newOrg) {
      updateFromOrg(newOrg, true);
      window.localStorage.setItem("orgData", JSON.stringify(profile));
    } else {
      setAlert({
        status: "error",
        message: ct("failedLoadingOrgSettings"),
        secondsToClose: 10,
      });
    }
  };

  useEffect(() => {
    if (user && user.agencyId) {
      fetchProfile();
      fetchOrg();
    } else {
      setProfile(getDefaultProfile());
      setOrg(getDefaultOrg());
    }
  }, [user?.agencyId]);

  useEffect(() => {
    //TODO: Remove this next line eventually
    window.localStorage.removeItem("profile");

    const cachedProfile = window.localStorage.getItem("agentProfile");
    const orgFromCache = window.localStorage.getItem("orgData");

    let locale =
      navigator.languages && navigator.languages.length > 0
        ? navigator.languages[0]
        : "en-US";

    let country =
      locale.split("-").length > 1
        ? (locale.split("-")[1] as SupportedCountries)
        : SupportedCountries.UnitedStates;

    let language =
      locale.split("-").length > 1
        ? (locale.split("-")[0] as SupportedLanguages)
        : SupportedLanguages.English;

    if (
      !Object.values(SupportedLanguages).find((l) => l == locale.split("-")[0])
    ) {
      locale = "en-US";
    }

    if (!Object.values(SupportedCountries).find((l) => l == country)) {
      country = SupportedCountries.UnitedStates;
    }

    const brand =
      (window.localStorage.getItem("brand") as BRANDS) ?? BRANDS.GAIL;

    setUiData({
      language,
      country,
      brand,
      isUsingGalactus: false,
      allowedCountries: [SupportedCountries.UnitedStates],
    });

    if (cachedProfile) {
      const newProfile = JSON.parse(cachedProfile) as AgentProfile;

      setProfile(newProfile);
    } else {
      setProfile({
        ...profile,
        languages: [language],
      });
    }

    if (orgFromCache) {
      const newOrg = JSON.parse(orgFromCache) as Org;

      updateFromOrg(newOrg, false);
    } else {
      setOrg({
        ...org,
        localization: locale,
      });
    }

    posthog.onFeatureFlags(() => {
      if (posthog.isFeatureEnabled("ui-allowed-countries")) {
        const countries = posthog.getFeatureFlagPayload(
          "ui-allowed-countries"
        ) as SupportedCountries[] | undefined;

        if (!countries || countries.length == 0) {
          setAllowedCountries(Object.values(SupportedCountries));
        } else {
          setAllowedCountries(countries);
        }
      }
    });
  }, []);

  const saveAgentProfile = async (updatedProfile: AgentProfileRequest) => {
    setIsSaving(true, ct("savingOrgSettings"));

    const newProfile = await api<AgentProfileRequest, AgentProfile>(
      getToken,
      "/api/profile",
      "PATCH",
      updatedProfile
    );

    setIsSaving(false, "");

    if (newProfile) {
      setAlert({
        status: "success",
        message: ct("savedOrgSettings"),
        secondsToClose: 4,
      });
      setProfile(newProfile);
      window.localStorage.setItem("agentProfile", JSON.stringify(newProfile));
    } else {
      setAlert({
        status: "error",
        message: ct("failedOrgSettings"),
        secondsToClose: 10,
      });
    }
  };

  const saveOrg = async (updateOrg: OrgRequest) => {
    setIsSaving(true, ct("savingOrgSettings"));

    const newOrg = await api<OrgRequest, Org>(
      getToken,
      "/api/org",
      "PATCH",
      updateOrg
    );

    setIsSaving(false, "");

    if (newOrg) {
      setAlert({
        status: "success",
        message: ct("savedOrgSettings"),
        secondsToClose: 10,
      });
      window.localStorage.setItem("orgData", JSON.stringify(newOrg));
      updateFromOrg(newOrg, true);
    } else {
      setAlert({
        status: "error",
        message: ct("failedOrgSettings"),
        secondsToClose: 10,
      });
    }
  };

  return {
    ui: {
      ...uiData,
      allowedCountries: allowedCountries ?? [SupportedCountries.UnitedStates],
    },
    agentProfile: profile,
    saveAgentProfile,
    org,
    saveOrg,
    refreshOrg: fetchOrg,
  };
};

export const ProfileContextProvider = ({
  children,
  setLanguage,
}: {
  children: React.ReactNode;
  setLanguage: (language: string) => void;
}) => {
  const profileFunction = useProfileFunction({ setAppLanguage: setLanguage });

  return (
    <ProfileContext.Provider value={profileFunction}>
      {children}
    </ProfileContext.Provider>
  );
};
