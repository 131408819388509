"use client";

import clsx from "clsx";
import { Toast } from "flowbite-react";
import { Modal } from "flowbite-react";
import dynamic from "next/dynamic";
import posthog from "posthog-js";
import { createContext, useContext, useEffect, useState } from "react";
import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { UpdateOrg } from "@/app/api/org/updateOrg";
import FancyLoader from "@/components/common/fancyLoader";
import Button from "@/components/library/Button";
import Icon, { ICON_SIZES } from "@/components/library/Icon";
import { useAuthContext } from "@/contexts/common/AuthContextProvider";
import { MainAlertProps } from "@/types/common/alerts";
import { HandleOpenBillingSession } from "@/utils/agencies/settings";

type PricingTableProps = {
  "pricing-table-id": string;
  "publishable-key": string;
  "customer-session-client-secret": string;
};

const January = dynamic(() => import("@/components/festive/january"), {
  loading: () => <FancyLoader />,
});

const February = dynamic(() => import("@/components/festive/february"), {
  loading: () => <FancyLoader />,
});

const March = dynamic(() => import("@/components/festive/march"), {
  loading: () => <FancyLoader />,
});

const April = dynamic(() => import("@/components/festive/april"), {
  loading: () => <FancyLoader />,
});

const November = dynamic(() => import("@/components/festive/november"), {
  loading: () => <FancyLoader />,
});

const December = dynamic(() => import("@/components/festive/december"), {
  loading: () => <FancyLoader />,
});

const StripePricingTable = (props: PricingTableProps) => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://js.stripe.com/v3/pricing-table.js";
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);
  return React.createElement("stripe-pricing-table", {
    "pricing-table-id": props["pricing-table-id"],
    "publishable-key": props["publishable-key"],
    "customer-session-client-secret": props["customer-session-client-secret"],
  });
};

type DiscountType = {
  code: string;
  discount: string;
};

type GenericModalProps = {
  open: boolean;
  message: string;
  description?: string;
};

type AlertProps = {
  setAlert: (settings: MainAlertProps) => void;
  dismissAlert: () => void;
  showToast: boolean;
  toastSettings: MainAlertProps;
  isLoading: GenericModalProps;
  isSaving: GenericModalProps;
  isConfirming: GenericModalProps & {
    mode: "delete" | "confirm" | "choice";
    choices?: string[];
    saveCallback: () => void;
    proceedCallback: () => void;
  };
  setIsLoading: (
    isSaving: boolean,
    message: string,
    description?: string
  ) => void;
  setIsSaving: (
    isSaving: boolean,
    message: string,
    description?: string
  ) => void;
  setIsConfirming: (
    isConfirming: boolean,
    mode: "delete" | "confirm" | "choice",
    message: string,
    saveCallback: () => Promise<void>,
    proceedCallback: () => Promise<void>,
    choices?: string[]
  ) => void;
};

const getIcon = (
  status: "success" | "error" | "warning" | "info"
):
  | "check-circle"
  | "circle-exclamation"
  | "triangle-exclamation"
  | "circle-info" => {
  switch (status) {
    case "success":
      return "check-circle";
    case "error":
      return "circle-exclamation";
    case "warning":
      return "triangle-exclamation";
    case "info":
      return "circle-info";
  }
};

export const AlertContext = createContext<AlertProps>({
  setAlert: () => {},
  dismissAlert: () => {},
  showToast: false,
  toastSettings: {
    status: "success",
    message: "",
  },
  isLoading: {
    open: false,
    message: "",
  },
  isSaving: {
    open: false,
    message: "",
    description: undefined,
  },
  isConfirming: {
    open: false,
    message: "",
    mode: "confirm",
    choices: undefined,
    saveCallback: () => {},
    proceedCallback: () => {},
  },
  setIsLoading: () => {},
  setIsSaving: () => {},
  setIsConfirming: () => {},
});

export const useAlert = () => useContext(AlertContext)!;

const useAlertFunction = (): AlertProps => {
  //Private State
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null);

  //Public State
  const [showToast, setShowToast] = useState(false);
  const [toastSettings, setToastSettings] = useState<MainAlertProps>({
    status: "success",
    message: "",
  });
  const [isSaving, setIsSaving] = useState<GenericModalProps>({
    open: false,
    message: "",
  });
  const [isLoading, setIsLoading] = useState<GenericModalProps>({
    open: false,
    message: "",
    description: "",
  });
  const [isConfirming, setIsConfirming] = useState<
    GenericModalProps & {
      mode: "delete" | "confirm" | "choice";
      choices?: string[];
      saveCallback: () => void;
      proceedCallback: () => void;
    }
  >({
    open: false,
    message: "",
    mode: "confirm",
    choices: undefined,
    saveCallback: () => {},
    proceedCallback: () => {},
  });

  //Public Methods
  const setAlert = (settings: MainAlertProps) => {
    setToastSettings(settings);
    setShowToast(true);

    if (timeoutId) {
      clearTimeout(timeoutId);
      setTimeoutId(null);
    }

    const secondsToClose = settings.secondsToClose || 0;

    if (secondsToClose > 0) {
      const timeout = setTimeout(() => {
        setShowToast(false);
      }, secondsToClose * 1000);

      setTimeoutId(timeout);
    }
  };

  const dismissAlert = () => {
    setShowToast(false);
    if (timeoutId) {
      clearTimeout(timeoutId);
      setTimeoutId(null);
    }
  };

  return {
    setAlert,
    dismissAlert,
    showToast,
    toastSettings,
    isLoading,
    setIsLoading: (isLoading, message, description) => {
      setIsLoading({
        open: isLoading,
        message: message,
        description: description,
      });
    },
    isSaving,
    setIsSaving: (isSaving, message, description) => {
      setIsSaving({
        open: isSaving,
        message: message,
        description: description,
      });
    },
    isConfirming,
    setIsConfirming: (
      isConfirming,
      mode,
      message,
      saveCallback,
      proceedCallback,
      choices
    ) => {
      setIsConfirming({
        open: isConfirming,
        message: message,
        mode: mode,
        choices: choices,
        saveCallback: saveCallback,
        proceedCallback: proceedCallback,
      });
    },
  };
};

export const AlertContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const alert = useAlertFunction();
  const { subscription, getToken } = useAuthContext();
  const { t: ot } = useTranslation("onboarding");
  const { t: ct } = useTranslation("common");

  const [showTermsModal, setShowTermsModal] = useState(false);
  const [showBillingModal, setShowBillingModal] = useState(false);
  const [showSubscriptionModal, setShowSubscriptionModal] = useState({
    show: false,
    clientSecret: "",
  });
  const [showLoadingModal, setShowLoadingModal] = useState({
    show: false,
    title: "",
    description: "",
  });
  const [discount, setDiscount] = useState<DiscountType | null>(null);

  const {
    dismissAlert,
    showToast,
    toastSettings,
    isLoading,
    isSaving,
    isConfirming,
    setIsConfirming,
  } = alert;

  const icon = getIcon(toastSettings.status);

  useEffect(() => {
    if (subscription.flags.needs_signing) {
      setShowTermsModal(true);
    }
  }, [subscription]);

  useEffect(() => {
    posthog.onFeatureFlags(function () {
      if (posthog.isFeatureEnabled("ui-discount-code")) {
        const discountData: DiscountType = posthog.getFeatureFlagPayload(
          "ui-discount-code"
        ) as DiscountType;

        setDiscount(discountData);
      }
    });
  }, []);

  const GetMonthlyLoadingAnimation = () => {
    const monthNumber = new Date().getMonth() + 1;

    if (monthNumber === 1) {
      return <January />;
    } else if (monthNumber === 2) {
      return <February />;
    } else if (monthNumber === 3) {
      return <March />;
    } else if (monthNumber === 4) {
      return <April />;
    } else if (monthNumber === 11) {
      return <November />;
    } else if (monthNumber === 12) {
      return <December />;
    }

    return <FancyLoader />;
  };

  return (
    <AlertContext.Provider value={alert}>
      {children}
      <Toast
        className={clsx(
          "fixed",
          "top-24",
          "md:top-4",
          "right-4",
          "bg-gray-100",
          "max-w-sm",
          "border",
          "z-[100]",
          {
            "!top-[-100px]": !showToast,
            "border-green-500": toastSettings.status === "success",
            "border-red-500": toastSettings.status === "error",
            "border-yellow-500": toastSettings.status === "warning",
            "border-blue-500": toastSettings.status === "info",
          }
        )}
      >
        <span className={clsx({ hidden: toastSettings.status !== "success" })}>
          <Icon
            icon={icon}
            className={clsx("text-green-500")}
            size={ICON_SIZES.XL}
          />
        </span>
        <span className={clsx({ hidden: toastSettings.status !== "error" })}>
          <Icon
            icon={icon}
            className={clsx("text-red-500")}
            size={ICON_SIZES.XL}
          />
        </span>
        <span className={clsx({ hidden: toastSettings.status !== "warning" })}>
          <Icon
            icon={icon}
            className={clsx("text-yellow-500")}
            size={ICON_SIZES.XL}
          />
        </span>
        <span className={clsx({ hidden: toastSettings.status !== "info" })}>
          <Icon
            icon={icon}
            className={clsx("text-blue-500")}
            size={ICON_SIZES.XL}
          />
        </span>
        <div className="pl-4 pr-2 text-sm font-normal w-full grid gap-2">
          {toastSettings.message.split("\n").map((line, i) => (
            <div key={i}>{line}</div>
          ))}
        </div>
        {toastSettings.btnLabel && toastSettings.btnAction && (
          <Button
            className={clsx("mx-2", "float-right", {
              "text-green-500": toastSettings.status === "success",
              "text-red-500": toastSettings.status === "error",
              "text-yellow-500": toastSettings.status === "warning",
              "text-blue-500": toastSettings.status === "info",
            })}
            type="tertiary"
            onClick={toastSettings.btnAction}
          >
            {toastSettings.btnLabel}
          </Button>
        )}
        <Toast.Toggle
          className={clsx("bg-gray-100")}
          onDismiss={() => dismissAlert()}
        />
      </Toast>
      <Modal
        id="terms-modal"
        title="Please Read and Accept Terms"
        show={showTermsModal}
      >
        <Modal.Header className={clsx("[&_button]:hidden")}>
          <h2>{ot("termsModalTitle")}</h2>
        </Modal.Header>
        <Modal.Body>
          <p>{ot("termsModalDescription")}</p>
          <p className={clsx("mt-4")}>{ot("termsModalDetails")}</p>
          <div className={clsx("mt-4", "grid", "grid-cols-2", "gap-4")}>
            <Button
              type="primary"
              href="https://www.meetgail.com/terms-conditions"
              target="_blank"
            >
              {ot("termsAndConditions")}
            </Button>
            <Button
              type="primary"
              href="https://www.meetgail.com/privacy-policy"
              target="_blank"
            >
              {ot("privacyPolicy")}
            </Button>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className={clsx("w-full")}>
            <Button
              type="primary"
              onClick={async () => {
                setShowTermsModal(false);
                setShowLoadingModal({
                  show: true,
                  title: "Activating Your Account",
                  description: "Activating Your Account",
                });

                const token = await getToken();
                await UpdateOrg(token!, {
                  details: {
                    needs_signing: false,
                  },
                });

                setShowLoadingModal({
                  show: false,
                  title: "",
                  description: "",
                });
              }}
              className={clsx("float-right", "ml-4")}
            >
              {ct("iAgree")}
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
      <Modal
        id="account-not-active-modal"
        title={ct("accountNotActiveTitle")}
        onClose={() => {
          setShowBillingModal(false);
        }}
        show={showBillingModal}
      >
        <Modal.Header>
          <h2>{ct("accountNotActiveTitle")}</h2>
        </Modal.Header>
        <Modal.Body>
          <p>{ct("accountNotActiveDescription")}</p>
        </Modal.Body>
        <Modal.Footer>
          <div className={clsx("w-full")}>
            <Button
              type="primary"
              onClick={() => {
                setShowBillingModal(false);
                HandleOpenBillingSession(getToken, alert.setAlert);
              }}
              className={clsx("float-right", "ml-4")}
            >
              <Icon icon="address-card" className={clsx("mr-2")} />
              <span>{ct("updateBilling")}</span>
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
      <Modal
        id="loading-modal"
        title="Loading"
        show={showLoadingModal.show}
        dismissible={false}
      >
        <Modal.Body>
          <p className={clsx("text-center")}>{showLoadingModal.description}</p>
          <FancyLoader />
        </Modal.Body>
      </Modal>
      <Modal
        id="confirming-modal"
        title="Confirming"
        show={isConfirming.open}
        dismissible={true}
        onClose={() => {
          setIsConfirming(
            false,
            "confirm",
            "",
            async () => {},
            async () => {}
          );
        }}
      >
        <Modal.Header>{isConfirming.message.split("\n")[0]}</Modal.Header>
        {isConfirming.message.split("\n").length > 1 && (
          <Modal.Body>
            {isConfirming.message.split("\n").map((message, index) => {
              if (index === 0) {
                return;
              }

              return <p key={index}>{message}</p>;
            })}
          </Modal.Body>
        )}
        <Modal.Footer>
          <div className="w-full">
            <div
              className={clsx(
                "grid",
                "gap-4",
                "grid-cols-1",
                "md:grid-cols-2",
                "float-right"
              )}
            >
              <Button
                type="primary"
                wide
                onClick={async () => {
                  await isConfirming.saveCallback();
                  setIsConfirming(
                    false,
                    "confirm",
                    "",
                    async () => {},
                    async () => {}
                  );
                }}
              >
                {isConfirming.mode === "delete"
                  ? ct("remove")
                  : isConfirming.mode === "confirm"
                  ? ct("saveAndContinue")
                  : isConfirming.choices && isConfirming.choices.length > 0
                  ? isConfirming.choices[0]
                  : ct("yes")}
              </Button>
              <Button
                type="secondary"
                wide
                onClick={async () => {
                  await isConfirming.proceedCallback();
                  setIsConfirming(
                    false,
                    "confirm",
                    "",
                    async () => {},
                    async () => {}
                  );
                }}
              >
                {isConfirming.mode === "delete"
                  ? ct("cancel")
                  : isConfirming.mode === "confirm"
                  ? ct("continueAnyways")
                  : isConfirming.choices && isConfirming.choices.length > 1
                  ? isConfirming.choices[1]
                  : ct("no")}
              </Button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
      <Modal
        id="subscription-modal"
        title={ot("readyToPurchase")}
        show={showSubscriptionModal.show}
        onClose={() => {
          setShowSubscriptionModal({
            show: false,
            clientSecret: showSubscriptionModal.clientSecret,
          });
        }}
        size={"4xl"}
      >
        <Modal.Header>
          <h2>{ot("readyToPurchase")}</h2>
        </Modal.Header>
        <Modal.Body>
          <div
            className={clsx(
              "mb-6",
              "grid",
              "gap-4",
              "grid-cols-1",
              "md:grid-cols-2"
            )}
          >
            <div className={clsx("max-w-sm", "mx-auto")}>
              <span className={clsx("mb-2", "text-lg", "block")}>You Can:</span>
              <ul
                className={clsx(
                  "list-disc",
                  "mx-auto",
                  "inline-block",
                  "mb-4",
                  "list-inside"
                )}
              >
                <li>{ot("readyToPurchasePoint1")}</li>
                <li>{ot("readyToPurchasePoint2")}</li>
                <li className={clsx("font-semibold")}>
                  {ot("readyToPurchasePoint3")}
                </li>
              </ul>
            </div>
            <div className={clsx("max-w-sm", "mx-auto")}>
              {ot("readyToPurchaseDescription")}
              {discount && (
                <>
                  <h3
                    className={clsx("mt-4", "mb-2", "text-lg", "font-semibold")}
                  >
                    {ot("weHaveADiscount")}
                  </h3>
                  <p>
                    <Trans
                      ns="onboarding"
                      i18nKey="discountDescription"
                      values={{
                        code: discount.code,
                        discount: discount.discount,
                      }}
                    >
                      Use Code
                      <span className={clsx("text-xl", "font-semibold")}>
                        {discount.code}
                      </span>
                      for
                      <span className={clsx("text-xl", "font-semibold")}>
                        {discount.discount}
                      </span>
                      off your first month
                    </Trans>
                  </p>
                </>
              )}
            </div>
          </div>
          <div className={clsx("grid", "checkout_container")}>
            {showSubscriptionModal.clientSecret ? (
              <StripePricingTable
                pricing-table-id={
                  process.env.NEXT_PUBLIC_STRIPE_PRICING_TABLE_ID || ""
                }
                publishable-key={
                  process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY || ""
                }
                customer-session-client-secret={
                  showSubscriptionModal.clientSecret
                }
              />
            ) : (
              <FancyLoader />
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            type="secondary"
            onClick={() =>
              setShowSubscriptionModal({
                show: false,
                clientSecret: showSubscriptionModal.clientSecret,
              })
            }
          >
            {ot("continueTryingGail")}
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={isLoading.open}>
        <Modal.Body>
          <h1
            className={clsx("text-center", "text-xl", {
              "mb-4": !!isLoading.description,
            })}
          >
            {isLoading.message}
          </h1>
          {isLoading.description && (
            <p className={clsx("text-center")}>{isLoading.description}</p>
          )}
          {GetMonthlyLoadingAnimation()}
        </Modal.Body>
      </Modal>
      <Modal show={isSaving.open}>
        <Modal.Body>
          <h1 className={clsx("text-center")}>{isSaving.message}</h1>
          {isSaving.description && (
            <p className={clsx("text-center")}>{isSaving.description}</p>
          )}
          {GetMonthlyLoadingAnimation()}
        </Modal.Body>
      </Modal>
    </AlertContext.Provider>
  );
};

export const SimpleAlertContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const alert = useAlertFunction();
  const { t: ct } = useTranslation("common");

  const {
    dismissAlert,
    showToast,
    toastSettings,
    isLoading,
    isSaving,
    isConfirming,
    setIsConfirming,
  } = alert;

  const icon = getIcon(toastSettings.status);

  return (
    <AlertContext.Provider value={alert}>
      {children}
      <Toast
        className={clsx(
          "fixed",
          "top-24",
          "md:top-4",
          "right-4",
          "bg-gray-100",
          "max-w-sm",
          "border",
          "z-[100]",
          {
            "!top-[-100px]": !showToast,
            "border-green-500": toastSettings.status === "success",
            "border-red-500": toastSettings.status === "error",
            "border-yellow-500": toastSettings.status === "warning",
            "border-blue-500": toastSettings.status === "info",
          }
        )}
      >
        <span className={clsx({ hidden: toastSettings.status !== "success" })}>
          <Icon
            icon={icon}
            className={clsx("text-green-500")}
            size={ICON_SIZES.XL}
          />
        </span>
        <span className={clsx({ hidden: toastSettings.status !== "error" })}>
          <Icon
            icon={icon}
            className={clsx("text-red-500")}
            size={ICON_SIZES.XL}
          />
        </span>
        <span className={clsx({ hidden: toastSettings.status !== "warning" })}>
          <Icon
            icon={icon}
            className={clsx("text-yellow-500")}
            size={ICON_SIZES.XL}
          />
        </span>
        <span className={clsx({ hidden: toastSettings.status !== "info" })}>
          <Icon
            icon={icon}
            className={clsx("text-blue-500")}
            size={ICON_SIZES.XL}
          />
        </span>
        <div className="pl-4 pr-2 text-sm font-normal w-full grid gap-2">
          {toastSettings.message.split("\n").map((line, i) => (
            <div key={i}>{line}</div>
          ))}
        </div>
        {toastSettings.btnLabel && toastSettings.btnAction && (
          <Button
            className={clsx("mx-2", "float-right", {
              "text-green-500": toastSettings.status === "success",
              "text-red-500": toastSettings.status === "error",
              "text-yellow-500": toastSettings.status === "warning",
              "text-blue-500": toastSettings.status === "info",
            })}
            type="tertiary"
            onClick={toastSettings.btnAction}
          >
            {toastSettings.btnLabel}
          </Button>
        )}
        <Toast.Toggle
          className={clsx("bg-gray-100")}
          onDismiss={() => dismissAlert()}
        />
      </Toast>
      <Modal
        id="confirming-modal"
        title="Confirming"
        show={isConfirming.open}
        dismissible={true}
        onClose={() => {
          setIsConfirming(
            false,
            "confirm",
            "",
            async () => {},
            async () => {}
          );
        }}
      >
        <Modal.Header>{isConfirming.message.split("\n")[0]}</Modal.Header>
        {isConfirming.message.split("\n").length > 1 && (
          <Modal.Body>
            {isConfirming.message.split("\n").map((message, index) => {
              if (index === 0) {
                return;
              }

              return <p key={index}>{message}</p>;
            })}
          </Modal.Body>
        )}
        <Modal.Footer>
          <div className="w-full">
            <div
              className={clsx(
                "grid",
                "gap-4",
                "grid-cols-1",
                "md:grid-cols-2",
                "float-right"
              )}
            >
              <Button
                type="primary"
                wide
                onClick={async () => {
                  await isConfirming.saveCallback();
                  setIsConfirming(
                    false,
                    "confirm",
                    "",
                    async () => {},
                    async () => {}
                  );
                }}
              >
                {isConfirming.mode === "delete"
                  ? ct("remove")
                  : isConfirming.mode === "confirm"
                  ? ct("saveAndContinue")
                  : isConfirming.choices && isConfirming.choices.length > 0
                  ? isConfirming.choices[0]
                  : ct("yes")}
              </Button>
              <Button
                type="secondary"
                wide
                onClick={async () => {
                  await isConfirming.proceedCallback();
                  setIsConfirming(
                    false,
                    "confirm",
                    "",
                    async () => {},
                    async () => {}
                  );
                }}
              >
                {isConfirming.mode === "delete"
                  ? ct("cancel")
                  : isConfirming.mode === "confirm"
                  ? ct("continueAnyways")
                  : isConfirming.choices && isConfirming.choices.length > 1
                  ? isConfirming.choices[1]
                  : ct("no")}
              </Button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
      <Modal show={isLoading.open}>
        <Modal.Body>
          <h1
            className={clsx("text-center", "text-xl", {
              "mb-4": !!isLoading.description,
            })}
          >
            {isLoading.message}
          </h1>
          {isLoading.description && (
            <p className={clsx("text-center")}>{isLoading.description}</p>
          )}
          <FancyLoader />
        </Modal.Body>
      </Modal>
      <Modal show={isSaving.open}>
        <Modal.Body>
          <h1 className={clsx("text-center")}>{isSaving.message}</h1>
          {isSaving.description && (
            <p className={clsx("text-center")}>{isSaving.description}</p>
          )}
          <FancyLoader />
        </Modal.Body>
      </Modal>
    </AlertContext.Provider>
  );
};
